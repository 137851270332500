import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { produce } from 'immer';

import { userActions, alertActions } from '../actions';
import { LoginLayout } from '../pages';

const styles = {
  container: {
    textAlign: 'center!important'
  },
  form: {
    margin: 'auto',
    width: '100%',
    maxWidth: '330px',
    display: 'block'
  }
};

const INITIAL_STATE = {
  username: '',
  email: '',
  password: '',
  password1: '',
  password2: '',
  captcha: '',
  remember: false,
  submitted: false,
  form: 'login'
};

class LoginRoute extends React.Component {
  constructor(props) {
    super(props);

    const { loggedIn, loggingIn, loggedOut } = this.props.authentication;

    // reset login status
    if (loggedIn === true) {
      this.props.history.push('/');
    } else if (loggedOut !== true && loggedIn === false && loggingIn === false) {
      userActions.checkSession(this.props.history);
    }

    this.state = { ...INITIAL_STATE };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRecover = this.handleRecover.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }

  handleChange(e) {
    let { name, value, type } = e.target;
    const { alert, dispatch } = this.props;

    if (type === 'checkbox') {
      value = e.target.checked;
    }

    this.setState({ [name]: value });
    this.setState({ submitted: false });

    if (Object.keys(alert).length > 0) {
      dispatch(alertActions.clear());
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password, captcha, remember, password1, password2 } = this.state;
    const { dispatch } = this.props;

    if (username && password) {
      userActions.login(this.props.history, {
        username,
        password,
        captcha: captcha.length > 0 ? captcha : false,
        remember,
        password1: password1.length > 0 ? password1 : false,
        password2: password2.length > 0 ? password2 : false
      });
    } else {
      dispatch(alertActions.error('Please insert username & password!'));
    }
  }

  handleRecover(e) {
    e.preventDefault();

    const { email, captcha } = this.state;
    const { dispatch } = this.props;

    if (!!email && !!captcha) {
      userActions
        .recover({ email, captcha })
        .then(() => {
          this.setState({ ...INITIAL_STATE });
          toast(
            'Wir senden Ihnen eine Email zum Zurücksetzen Ihres Passworts. Bitte überprüfen Sie Ihren Posteingang.'
          );
        })
        .catch(() => {
          this.setState({ submitted: true });
        });
    } else {
      dispatch(alertActions.error('Please insert your email and the displayed characters!'));
    }
  }

  toggleForm(type) {
    this.setState(state =>
      produce(state, draftState => {
        draftState.form = type;
        draftState.submitted = false;
        draftState.captcha = '';
      })
    );
  }

  render() {
    const { username, email, password, captcha, submitted, password1, password2 } = this.state;
    const { authentication } = this.props;

    return (
      <LoginLayout>
        <div className='container-fluid align-middle d-flex flex-fill bg-breadcrump' style={styles.container}>
          {this.state.form === 'login' && (
            <form style={styles.form} onSubmit={this.handleSubmit}>
              <h1 className='h3 mb-3 font-weight-normal text-uppercase'>Sign in</h1>

              {(submitted || authentication.loggedOut) && authentication.error && (
                <h6 className='text-danger'>{authentication.error}</h6>
              )}

              {authentication.loggedOut && !authentication.error && (
                <h6 className='text-info'>You have been logged out.</h6>
              )}

              <div className={'form-group'}>
                <label htmlFor='inputEmail' className='form-control-label sr-only'>
                  Email address
                </label>
                <input
                  type='text'
                  name='username'
                  id='inputEmail'
                  className={'form-control' + (submitted && !username ? ' is-invalid' : '')}
                  placeholder='Username'
                  required=''
                  autoFocus
                  data-cip-id='inputEmail'
                  autoComplete='username email'
                  value={username}
                  onChange={this.handleChange}
                />
                {submitted && !username && <div className='invalid-feedback'>Username is required</div>}
              </div>

              <div className={'form-group'}>
                <label htmlFor='inputPassword' className='form-control-label sr-only'>
                  Password
                </label>
                <input
                  type='password'
                  name='password'
                  id='inputPassword'
                  className={'form-control' + (submitted && !password ? ' is-invalid' : '')}
                  placeholder='Password'
                  required={''}
                  data-cip-id='inputPassword'
                  autoComplete='current-password'
                  value={password}
                  onChange={this.handleChange}
                />
                {submitted && !password && <div className='invalid-feedback'>Password is required</div>}
              </div>

              {authentication.pwexpired && (
                <>
                  <hr className='divider' />
                  <div className='text-muted text-uppercase'>New Password</div>
                  <div className={'form-group'}>
                    <label htmlFor='inputPassword1' className='form-control-label sr-only'>
                      New Password
                    </label>
                    <input
                      type='password'
                      name='password1'
                      id='inputPassword1'
                      className={'form-control'}
                      placeholder='enter new password'
                      required={''}
                      data-cip-id='inputPassword1'
                      autoComplete='off'
                      value={password1}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className={'form-group'}>
                    <label htmlFor='inputPassword2' className='form-control-label sr-only'>
                      New Password
                    </label>
                    <input
                      type='password'
                      name='password2'
                      id='inputPassword2'
                      className={'form-control'}
                      placeholder='repeat new password'
                      required={''}
                      data-cip-id='inputPassword2'
                      autoComplete='off'
                      value={password2}
                      onChange={this.handleChange}
                    />
                  </div>
                  <hr className='divider' />
                </>
              )}

              {authentication.captcha && (
                <div className={'form-group form-row'}>
                  <div className='col'>
                    <img src={`${process.env?.PUBLIC_URL ?? ''}/captcha.php`} alt='' />
                  </div>
                  <div className='col'>
                    <label htmlFor='inputCaptcha' className='form-control-label sr-only'>
                      Captcha
                    </label>
                    <input
                      type='text'
                      name='captcha'
                      id='inputCaptcha'
                      className={'form-control' + (submitted && !captcha ? ' is-invalid' : '')}
                      placeholder='enter characters'
                      required={''}
                      data-cip-id='inputCaptcha'
                      autoComplete='current-captcha'
                      value={captcha}
                      onChange={this.handleChange}
                    />
                    {submitted && !captcha && <div className='invalid-feedback'>Captcha is required</div>}
                  </div>
                </div>
              )}

              <div className='d-flex justify-content-between'>
                <div className='form-group custom-control form-control-sm custom-checkbox mr-sm-2'>
                  <input
                    type='checkbox'
                    id={'rememberme'}
                    name={'remember'}
                    className='custom-control-input pointer'
                    defaultChecked={false}
                    onChange={this.handleChange}
                  />
                  <label className='custom-control-label pointer' htmlFor='rememberme'>
                    Remember me
                  </label>
                </div>

                <div className='pointer' onClick={() => this.toggleForm('recover')}>
                  <small>Lost your password?</small>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  className='btn btn-secondary'
                  type='submit'
                  disabled={submitted || !username || !password || (authentication.captcha && !captcha)}
                >
                  {authentication.loggingIn ? (
                    <>
                      <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                      {' loading'}
                    </>
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </form>
          )}

          {this.state.form === 'recover' && (
            <form style={styles.form} onSubmit={this.handleRecover}>
              <h3 className='font-weight-normal text-uppercase mb-3'>Account Recovery</h3>

              {submitted && authentication.error && <h6 className='text-danger'>{authentication.error}</h6>}

              {submitted && !authentication.error && (
                <>
                  <h6 className='text-info'>
                    We sent you an confirmation if the email address you entered is registered.
                  </h6>
                  <div className='d-flex justify-content-between mt-4'>
                    <div />
                    <button className='btn btn-secondary btn-sm' onClick={() => this.toggleForm('login')}>
                      back
                    </button>
                  </div>
                </>
              )}

              {(!submitted || authentication.error) && (
                <>
                  <div className='mb-3'>
                    Type in your email address or user name to reset your password. We will send you an email with
                    further instructions.
                  </div>

                  <div className={'form-group'}>
                    <label htmlFor='inputEmail' className='form-control-label sr-only'>
                      Email address
                    </label>
                    <input
                      type='text'
                      name='email'
                      className={'form-control' + (submitted && !email ? ' is-invalid' : '')}
                      placeholder='enter username or email address...'
                      required=''
                      autoFocus
                      autoComplete='email'
                      value={email}
                      onChange={this.handleChange}
                    />
                    {submitted && !email && <div className='invalid-feedback'>Username or email is required</div>}
                  </div>

                  <div className={'form-group form-row'}>
                    <div className='col'>
                      <img src={`${process.env?.PUBLIC_URL ?? ''}/captcha.php`} alt='' />
                    </div>
                    <div className='col'>
                      <label htmlFor='inputCaptcha' className='form-control-label sr-only'>
                        Captcha
                      </label>
                      <input
                        type='text'
                        name='captcha'
                        id='inputCaptcha'
                        className={'form-control' + (submitted && !captcha ? ' is-invalid' : '')}
                        placeholder='enter characters'
                        required={''}
                        data-cip-id='inputCaptcha'
                        autoComplete='current-captcha'
                        value={captcha}
                        onChange={this.handleChange}
                      />
                      {submitted && !captcha && <div className='invalid-feedback'>Captcha is required</div>}
                    </div>
                  </div>

                  <div className='d-flex justify-content-between mb-4'>
                    <div />
                    <div className='pointer' onClick={() => this.toggleForm('login')}>
                      <small>Returned to your mind?</small>
                    </div>
                  </div>

                  <div className='d-flex justify-content-end'>
                    <button className='btn btn-secondary' type='submit' disabled={submitted || !email || !captcha}>
                      {authentication.loggingIn ? (
                        <>
                          <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                          {' loading'}
                        </>
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </>
              )}
            </form>
          )}
        </div>
      </LoginLayout>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.authentication,
  settings: state.settings,
  alert: state.alert
});

const connectedRoute = connect(mapStateToProps, null)(LoginRoute);

export default connectedRoute;
